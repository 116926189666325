import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import LoaderSVG from "../img/loader.svg";

export default function Roles({ selectedServer, type, title, user }) {
  const [roles, setRoles] = useState([]);
  const [confirm, setConfirm] = useState({ role: "", action: "" });
  const [addRole, setAddRole] = useState(false);
  const [newRole, setNewRole] = useState("");
  const [editedRole, setEditedRole] = useState("");
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    getRolesDB();
  }, [selectedServer]);

  const getRolesDB = async () => {
    setLoading(true);
    const response = await fetch("https://oldapi.caigo.io/api/dash" + type + "/roles/get", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        serverIDREQ: selectedServer.serverID,
        user: user.id,
      }),
    });

    const data = await response.json();

    if (data.success) {
      setRoles(data.roles);
      setLoading(false);
    }

    if (data.relocate) {
      navigate("/");
      window.location.reload();
    }
  };

  const setRolesDB = async (newRolesArr) => {
    const response = await fetch("https://oldapi.caigo.io/api/dash" + type + "/roles/add", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        serverIDREQ: selectedServer.serverID,
        newRoles: newRolesArr,
      }),
    });

    const data = await response.json();

    if (data.success) {
      getRolesDB();
    }
  };

  const AddRole = () => {
    setRolesDB([newRole]);
    setAddRole(!addRole);
    setNewRole("");
  };

  const EditRole = async (oldRole) => {
    if (editedRole === "") {
      DeleteRole(oldRole);
    }
    const response = await fetch("https://oldapi.caigo.io/api/dash" + type + "/roles/edit", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        serverIDREQ: selectedServer.serverID,
        oldRole: oldRole,
        replacementRole: editedRole,
      }),
    });

    const data = await response.json();

    if (data.success) {
      getRolesDB();
    }
    cancelEdit();
  };

  const DeleteRole = async (role) => {
    const response = await fetch("https://oldapi.caigo.io/api/dash" + type + "/roles/delete", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        serverIDREQ: selectedServer.serverID,
        toDelete: role,
      }),
    });

    const data = await response.json();

    if (data.success) {
      getRolesDB();
    }
    cancelEdit();
  };

  const cancelEdit = () => {
    setConfirm({ role: "", action: "" });
    setEditedRole("");
  };

  return (
    <div className="flex flex-col w-full h-auto overflow-x-scroll">
      <div className="m-2 sm:m-10">
        {title === "admin" ? (
          <div className="text-white">
            Admin roles in <span className="font-bold">{selectedServer.serverName}</span>
          </div>
        ) : (
          <div className="text-white">
            The following roles in <span className="font-bold">{selectedServer.serverName}</span> have access to
            CopTools:{" "}
          </div>
        )}

        <div className="bg-[#1d2638] rounded p-5 mt-2 w-fit overflow-x-scroll">
          {loading ? (
            <img src={LoaderSVG} alt="" className="p-20" />
          ) : roles.length === 0 ? (
            <div className="text-white">No roles found</div>
          ) : (
            roles.map((r, i) => {
              return (
                <div className="flex flex-row justify-between gap-5" key={i}>
                  {confirm.role === r && confirm.action === "edit" ? (
                    <input
                      type="text"
                      className="bg-[#141b29] text-white rounded w-fit"
                      value={editedRole}
                      onChange={(e) => setEditedRole(e.target.value)}
                    />
                  ) : null}
                  {confirm.role !== r || confirm.action !== "edit" ? <p className="text-white truncate">{r}</p> : null}

                  <div className="flex items-center gap-2">
                    {confirm.role === r ? null : (
                      <>
                        <button
                          onClick={() => {
                            setConfirm({ role: r, action: "edit" });
                            setEditedRole(r);
                          }}
                        >
                          <PencilIcon className="h-[20px] text-blue-500" />
                        </button>
                        <button onClick={() => setConfirm({ role: r, action: "delete" })}>
                          <TrashIcon className="h-[20px] text-red-500" />
                        </button>
                      </>
                    )}

                    {confirm.role === r ? (
                      <div className="flex gap-2 font-medium text-white">
                        <button
                          className={confirm.action === "edit" ? "text-blue-500" : "text-red-500"}
                          onClick={() => {
                            confirm.action === "edit" ? EditRole(r) : DeleteRole(r);
                          }}
                        >
                          Confirm
                        </button>
                        <button onClick={() => cancelEdit()}>Cancel</button>
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })
          )}
        </div>
        <div className="flex items-center gap-3 mt-3 font-medium text-white">
          {addRole ? (
            <div>
              <input
                type="text"
                className="bg-[#1d2638] text-white p-1 font-normal"
                onChange={(e) => setNewRole(e.target.value)}
                value={newRole}
              />
              <div className="flex gap-2 font-medium text-white">
                <button className="text-green-500" onClick={() => AddRole()}>
                  Confirm add a role
                </button>
                <button onClick={() => setAddRole(!addRole)}>Cancel</button>
              </div>
            </div>
          ) : (
            <button className="px-2 py-1 bg-green-500 rounded" onClick={() => setAddRole(!addRole)}>
              Add a role
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
