import { useEffect, useState } from "react";

export default function DeviceManagement() {
  const [device, setDevice] = useState("");

  useEffect(() => {
    checkForUser();
  }, []);

  const checkForUser = async () => {
    fetch("https://oldapi.caigo.io/api/dash/user/check", {
      method: "POST",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((body) => {
        if (body.success) {
          if (body.device) {
            setDevice(body.device);
          }
        }
      });
  };

  const resetDevice = async () => {
    fetch("https://oldapi.caigo.io/api/dash/user/reset", {
      method: "POST",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((body) => {
        if (body.success) {
          setDevice("");
        }
      });
  };

  return (
    <div className="flex flex-col w-full h-[calc(100vh-60px)] bg-[#151A26]">
      <div className="w-auto h-auto text-white bg-[#121620] p-5 m-5 rounded-lg">
        <h1 className="text-lg font-bold">Instructions</h1>
        <p className="mt-3">If your profile is binded to a device you can reset it here.</p>
      </div>

      <div className="w-auto h-auto text-white bg-[#121620] p-5 m-5 rounded-lg">
        <h1 className="text-lg font-bold">Reset Device</h1>
        {device ? (
          <div className="flex flex-row items-center gap-5">
            <p>Current device: {device}</p>
            <button
              onClick={resetDevice}
              className="px-5 py-2 mt-3 transition border border-white rounded-lg hover:bg-gray-600"
            >
              Reset Device
            </button>
          </div>
        ) : (
          <p className="mt-3">You can bind your profile.</p>
        )}
      </div>
    </div>
  );
}
