import WindowsSVG from "./img/windows.svg";
import AppleSVG from "./img/apple.svg";

export default function Download() {
  return (
    <div className="flex flex-col w-full h-[calc(100vh-60px)] bg-[#151A26]">
      <div className="w-auto h-auto text-white bg-[#121620] p-5 m-5 rounded-lg">
        <h1 className="text-lg font-bold">Instructions</h1>
        <p className="mt-3">To download CopTools desktop application simply click on desired operating system icon.</p>

        <p>{"We would suggest using auto-updates once you have CopTools installed on your computer. (coming soon)"}</p>
      </div>

      <div className="w-auto h-auto text-white bg-[#121620] p-5 m-5 rounded-lg">
        <h1 className="text-lg font-bold">Windows</h1>
        <button className="flex items-center gap-3 px-5 py-2 mt-3 transition border border-white rounded-lg hover:bg-gray-600">
          <a href="https://caigo-main-bucket.fra1.cdn.digitaloceanspaces.com/CopTools_2.2.1.exe" download>
            Download
          </a>
          <img src={WindowsSVG} className="w-8" />
        </button>
      </div>
      <div className="w-auto h-auto text-white bg-[#121620] p-5 m-5 rounded-lg">
        <h1 className="text-lg font-bold">Mac</h1>
        <button className="flex items-center gap-3 px-5 py-2 mt-3 transition border border-white rounded-lg hover:bg-gray-600">
          <a href="https://caigo-main-bucket.fra1.cdn.digitaloceanspaces.com/CopTools-2.2.1.dmg" download>
            Download
          </a>
          <img src={AppleSVG} className="w-8 fill-white" fill="white" />
        </button>
      </div>
    </div>
  );
}
