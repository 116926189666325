import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import {
  SquaresPlusIcon,
  ArrowDownTrayIcon,
  ArrowLeftOnRectangleIcon,
  KeyIcon,
  ExclamationTriangleIcon,
  EyeIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

import ServerSelector from "./ServerSelector";

const menu = [
  { name: "Download", path: "/", icon: ArrowDownTrayIcon },
  { name: "Device Management", path: "/devices", icon: KeyIcon },
  { name: "Report a Bug", path: "/bug_report", icon: ExclamationTriangleIcon },
  { name: "Suggest a Feature", path: "/new_features", icon: SquaresPlusIcon },
];

const adminMenu = [
  { name: "App Appearance", path: "/admin_app_appearance", icon: EyeIcon },
  { name: "Role Management", path: "/admin_roles", icon: UsersIcon },
];

function ClassNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Sidebar({ user, selectedServer, setSelectedServer }) {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (user.adminInfo.length > 0) {
      setSelectedServer(user.adminInfo[0]);
    }
  }, []);

  const mappedMenu = menu.map((m) => MenuItem(m, open));
  const mappedAdminMenu = adminMenu.map((m) => AdminMenuItem(m, open));

  const logout = () => {
    fetch("https://oldapi.caigo.io/api/dash/auth/logout", {
      method: "POST",
      credentials: "include",
    }).then((res) => {
      res.json();
      window.location.reload();
    });
  };

  if (open)
    return (
      <div className="h-[calc(100vh-60px)] min-w-[220px] w-[220px] bg-[#121620] absolute sm:relative">
        {/* Menu */}
        <div className="grid gap-2 mt-3">
          {user.adminInfo.length > 0 ? (
            <div className="flex flex-col rounded-md border border-[#7B01FC] mx-2">
              <p className="text-[10px] text-[#7B01FC] ml-3 mt-2">ADMIN</p>
              <ServerSelector selected={selectedServer} setSelected={setSelectedServer} servers={user.adminInfo} />
              <div className="-ml-2">{mappedAdminMenu}</div>
            </div>
          ) : null}

          {mappedMenu}
        </div>

        {/* Close */}
        <div
          className="absolute bottom-[120px] right-[10px] p-2 bg-[#1E2535] w-fit rounded-[5px] text-white hover:bg-gray-700 cursor-pointer"
          onClick={() => setOpen(false)}
        >
          <ArrowLeftIcon className="h-4" />
        </div>

        {/* Logout */}
        <button
          onClick={logout}
          className="absolute bottom-[50px] right-[14px] p-2 bg-[#1E2535] w-[192px] rounded-[5px] text-white hover:bg-gray-700 cursor-pointer flex items-center gap-3 justify-center"
        >
          <ArrowLeftOnRectangleIcon className="h-[26px]" />
          <p>Logout</p>
        </button>
      </div>
    );

  return (
    <div className="h-[calc(100vh-60px)] min-w-[70px] w-[70px] bg-[#121620] relative">
      {/* Menu */}
      <div className="grid gap-2 mt-3">
        {user.adminInfo.length > 0 ? mappedAdminMenu : null}
        {mappedMenu}
      </div>

      {/* Open */}
      <div
        className="absolute bottom-[120px] right-[18px] p-2 bg-[#1E2535] w-fit rounded-[5px] text-white hover:bg-gray-700 cursor-pointer"
        onClick={() => setOpen(true)}
      >
        <ArrowRightIcon className="h-4" />
      </div>

      {/* Logout */}
      <button className="absolute bottom-[50px] right-[14px] p-2 bg-[#1E2535] w-fit rounded-[5px] text-white hover:bg-gray-700 cursor-pointer">
        <ArrowLeftOnRectangleIcon className="h-[26px]" />
      </button>
    </div>
  );
}

function MenuItem(m, open) {
  if (open)
    return (
      <NavLink
        key={m.path}
        end={m.path === "/" ? true : false}
        to={m.path}
        className={(nav) =>
          ClassNames(
            nav.isActive ? "font-semibold" : "opacity-50 hover:opacity-75",
            "flex w-full gap-2 h-[40px] items-center pl-3 outline-0"
          )
        }
      >
        <div className="h-full w-[40px] flex items-center justify-center">
          <m.icon className="h-[20px] text-white" />
        </div>
        <p className="text-white text-[15px]">{m.name}</p>
      </NavLink>
    );

  return (
    <NavLink
      end
      to={m.path}
      className={(nav) =>
        ClassNames(
          nav.isActive ? "font-semibold bg-gray-800" : "opacity-50 hover:opacity-75 hover:bg-[#1E2535]",
          "flex w-[50px] h-[50px] justify-center items-center rounded-md ml-[10px] outline-0"
        )
      }
    >
      <m.icon className="h-[26px] text-white" />
    </NavLink>
  );
}

function AdminMenuItem(m, open) {
  if (open)
    return (
      <NavLink
        key={m.path}
        end={m.path === "/" ? true : false}
        to={m.path}
        className={(nav) =>
          ClassNames(
            nav.isActive ? "font-semibold" : "opacity-50 hover:opacity-75",
            "flex w-full gap-2 h-[40px] items-center pl-3 outline-0"
          )
        }
      >
        <div className="h-full w-[40px] flex items-center justify-center">
          <m.icon className="h-[20px] text-white" />
        </div>
        <p className="text-white text-[15px]">{m.name}</p>
      </NavLink>
    );

  return (
    <NavLink
      end
      to={m.path}
      className={(nav) =>
        ClassNames(
          nav.isActive ? "font-semibold bg-gray-800" : "opacity-50 hover:opacity-75 hover:bg-[#1E2535]",
          "flex w-[50px] h-[50px] justify-center items-center rounded-md ml-[10px] outline-0"
        )
      }
    >
      <m.icon className="h-[26px] text-white" />
    </NavLink>
  );
}
